
.img{
    border-width: 15px;
    border-style: solid;
    border-color: #FF023E;
    padding: 0 0px;
    margin: 0 0px;
    max-width: 800px;
    justifyContent: 'center'

    /*height: 600px;*/
    /*width: 800px;*/
}
.text {
    color: #000000;
    font-size: 24px;
    font-family: helvetica, arial, sans-serif;
    font-weight: 600;
    text-align: center;
    line-height: 0.5px;
}
.p {
    color: #000000;
    font-size: 18px;
    font-family: helvetica, arial, sans-serif;
    font-weight: 600;
    text-align: center;
    line-height: 0.5px;
}