.header{
    grid-area: h;
    background-color: #506958;
}
.contact{
    font-size: 20px;
    color: white;
    padding: initial;
    line-height: 40px;
}
.russen{
    margin-left: 150px;
}
/*.header img{*/
/*    width: 250px;*/
/*    margin: 20px;*/

/*}*/