/*.content{*/
/*    grid-area: c;*/
/*    background-color: #506958;*/
/*}*/
/*.img{*/
/*    padding: 10px;*/
/*    size: A3;*/
/*}*/
.blokName{
    background-color: #333E37;
}
.blokNameZaglushka{
    background-color: #506958;
}
.blokFont{
    font-size: 20px;
    color: black;
    font-family: "Arial";
    padding: 20px;
    /*margin-left: 50px;*/
    /*margin: 30px;*/
}
.blokNameFont{
    font-size: 20px;
    color: white;
    font-family: "Arial";
    padding: 20px;

}
.NEWS{
    color: maroon;
    font-family: Arial;
    font-size: 25px;
    font-weight: bolder;
}
.blokText{
    font-size: 30px;
    color: white;
    font-family: "Arial Narrow";
    padding: 20px;
    align-content: center;
    /*margin: 30px;*/
}
.fontLink a{
    color: white;
    padding: 0px;
    font-family: Arial;
    font-size: 20px;
    /*line-height: 40px;*/
    /*text-decoration: none;*/
}
.fontLinkSale a {
    color: maroon;
    padding: 30px;
    font-family: Arial;
    font-size: 20px;
}
.fontLink a.activeLink{
    color: maroon;
    /*line-height: 40px;*/
    font-size: 20px;
}

.img{
    width: 50px;
    height: 50px;
}
.fotoGog{
    width: 600px;
    height: 440px;
    margin-left: 100px;

}
.DORHAN{
    width: 700px;
    height: 440px;
    margin-left: 100px;
}




/*}*/
/*.item{*/

/*}*/
