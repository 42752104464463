.h1{
    font-family: Arial;
    color: white;

}
.purchase{
    font-size: 18px;
    font-family: Arial;
    color: white;
    text-indent: 20px;
}
.description{
    font-size: 18px;
    font-family: Arial;
    color: white;
    text-indent: 20px;
}
.img{
    margin-left: 300px;
}