.nav{
    grid-area: n;
    background-color: #506958;
    padding: 20px;

}
.fontLink a{
    color: white;
    /*padding: 30px;*/
    font-family: Arial;
    font-size: 25px;
    line-height: 40px;
    text-decoration: none;
}
.fontLink a.activeLink{
    color: darkgray;
    line-height: 40px;
}
.FontNews{
    color: maroon;
    font-family: Arial;
    margin-left: 30px;

}
.imgNews{
    padding: 30px;
    width: 250px;
}
.logoImg{
    margin-left: 30px;
    margin-top: 20px;
}
/*.link{*/
/*        color: white;*/
/*        padding: 15px;*/
/*        font-family: Arial;*/
/*        font-size: 25px;*/
/*        line-height: 5px;*/

/*}*/
/*.item{*/
/*    color: darkgray;*/
/*    padding: 15px;*/
/*    font-family: Arial;*/
/*    font-size: 25px;*/
/*    line-height: 5px;*/
/*}*/
/*.item.active{*/
/*    color: cornflowerblue;*/
/*    padding: 10px;*/
/*    font-family: Arial;*/
/*    font-size: 25px;*/
/*}*/