.blokName{
    background-color: #333E37;
}
.blokFont{
    font-size: 20px;
    color: black;
    font-family: "Times New Roman";
    padding: 25px;
    /*margin-left: 50px;*/
    /*margin: 30px;*/
}
.blokNameFont{
    font-size: 25px;
    color: white;
    font-family: "Times New Roman";
    padding: 20px;

}
.fontTable{
    font-size: 18px;
    font-family: "Times New Roman";
    color: black;
}
.text{
    font-family: "Times New Roman";
    font-size: 25px;
    color: black;
}
.fontLink a{
    color: white;
    padding: 30px;
    font-family: Arial;
    font-size: 20px;
    /*line-height: 40px;*/
    /*text-decoration: none;*/
}
.fontLinkSale a {
    color: maroon;
    padding: 30px;
    font-family: Arial;
    font-size: 20px;
}

.fontLink a.activeLink{
    color: maroon;
    /*line-height: 40px;*/
    font-size: 20px;
}
.fontLink a.activeLinkSale{
    color: maroon;
    font-size: 20px;
    font-family: Arial;
    font-size: 20px;
}
.p{
    color: maroon;
}
