.fotter {
    grid-area: f;
    background-color: #596e60;
    height: 120px;
}
.p{
    margin-left: 100px;
    color: black;
    font-family: Arial;
    font-size: 20px;
    font-weight: bold;


}
.icon{
    width: 60px;
    height: 60px;
    margin-left: 3px;
}