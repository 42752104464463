.img{
    width: 900px;
    height: 640px;
    /*margin-left: 180px;*/

}
.imgAvatar{
    width: 300px;
    height: 300px;
    /*margin-left: 180px;*/

}
.font{
    font-size: 22px;
    font-family: -apple-system;
    color: black;
}
.fontTable{
    font-size: 18px;
    font-family: -apple-system;
    color: black;
}
.H3{
    font-family: Arial;
    color: black;

}
.H1{
    font-family: -apple-system;
    color: black;

}
.text{
    color: black;
    font-family: -apple-system;
    line-height: 1.8;
    font-size: 20px;
}