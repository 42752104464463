.right{
    grid-area: r;
    background-color: #506958;
    padding-left: 100px;
}
.font{
    font-size: 18px;
    font-family: Arial;
    color: white;
}
.fontLink a{
    color: white;
    /*padding: 30px;*/
    font-family: Arial;
    font-size: 20px;
    /*line-height: 40px;*/
    /*text-decoration: none;*/
}