.app-wrapper{
    display: grid;

    grid-template-areas: "n h r " "n c r " "f f f";


    grid-template-rows: 250px auto 100px;
    grid-auto-columns: 3fr 10fr 5fr;
}
.app-wrapper-content{
    grid-area: c;
    background-color: #506958;
}



/*.app-wrapper {*/
/*    margin: 0 auto;*/
/*    display: grid;*/
/*    width: 1200px;*/

/*    grid-template-areas: "h h" "n c";*/


/*    grid-template-rows: 60px 1fr;*/
/*    grid-auto-columns: 2fr 10fr;*/
/*    !*grid-gap: 10px;*!*/
/*}*/

/*.footer {*/
/*    grid-area: f;*/
/*  background-color: darkred;*/
/*}*/

/*.nav {*/
/*    grid-area: n;*/
/*  background-color: chartreuse;*/
/*}*/

/*.content {*/
/*    grid-area: c;*/
/*  background-color: blue;*/
/*}*/