.h3{
    font-size: 18px;
    font-family: Arial;
    color: white;

}
.H2 {
    font-size: 30px;
    color: white;
    font-family: "Arial Narrow";
    padding: 20px;

}