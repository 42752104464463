.font{
    font-size: 18px;
    font-family: Arial;
    color: white;
    text-indent: 20px;
}
.H5{
    font-family: Arial;
    color: white;
}
.H3{
    font-family: Arial;
    color: white;

}
.Img{

    margin-left: 300px;
    width: 400px;
    height: 270px;

}